@import "~sass-includes/index";

.global-nav {
  width: 100%;
  background: $black;
  height: 40px;
}
.global-nav__wrapper {
  @include centered($max-width);
  height: 100%;
  display: flex;
  align-items: center;
}
.global-nav__menu {
  svg {color: $white}
  margin-right: 20px;
}
.global-nav__logo {
  text-transform: uppercase;
  color: $blue-light;
  font-weight: 500;
  font-size: $font-size-sm;
  position: relative;
  text-decoration: none;
  top: -1px;
  &[data-disabled="true"] {
    color: $blue-light;
  }
}
.global-nav__center {
  margin-left: auto;
  position: relative;
  top: -2px;
}

.global-nav__student-view {
  color: $white;
  font-size: 11px;
  margin-left: 40px;
  border: 1px solid $white;
  padding: 5px 20px;
  border-radius: 50px;
}
.global-nav__courses {
  font-size: $font-size-xs;
  color: $white;
  top: -1px;
  cursor: pointer;
  text-decoration: none;
}
.global-nav__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  font-size: $font-size-xs;
  top: -1px;
  font-weight: bold;
  font-size: $font-size-sm;
  line-height: 140%;
  > div {
    position: relative;
    margin: 0 6px;
    color: $blue-light;
  }
  .global-nav__support-wrap {
    a {
      color: $blue-light;
    }
  }
  .global-nav__user-menu-wrap {
    svg {
      fill: $blue-light;
    }
  }
  .global-nav__user-menu-popup {
    @extend .codon-card;
    z-index: $zindexPopup;
    position: absolute;
    top: 24px;
    right: 0;
    min-width: 200px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 10px 8px;
    color: $black;
    li {
      svg {
        fill: $black;
      }

    }
    li.dropdown-link {
      svg {
        fill: $blue-dark;
      }
    }
    .dropdown-link {
      color: $purple;
    }
    svg {
      height: 18px;
      width: 18px;
    }
    ul, li {
      text-indent: 0;
      list-style-type: none;
      margin: 0;
    }
    ul {
      width: 100%;
      @extend %list-reset;
    }
    li {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: $font-size-sm;
      line-height: 140%;
      padding: 16px 24px;
      svg {
        margin-right: 8px;
      }
    }
  }
}
