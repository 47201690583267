/*
 * Here lives all the configuration for the site's theme.
 * This will automatically be available to any scss file
 * that loads in /sass-includes/index
 */

$breakpoints: (
  xl: ( min: 1200px),
  lg: ( min: 701px, max: 860px),
  md: ( min: 421px, max: 700px),
  sm: ( max: 420px)
);

$perspective-depth: 1000px;
$max-width: 1240px;

$zindexStandardTooltip:       500;
$zindexModal:                1020;
$zindexActionBar:            2000;
$zindexModalTooltip:         8000;
$zindexModalOverlay:         9000;
$zindexPopup:               10000; // also used for confirmation prompt

// Codon Design Library Colors
$white:             #FFFFFF;
$black:             #2F353C;
$true-black:        #000000;

$red-dark:          #8C1F07;
$red:               #DA2D07;
$red-light:         #DB6044;

$green-dark:        #2F7258;
$green:             #17875C;
$green-light:       #BEF4DF;

$aquamarine-dark:   #39AC80;
$aquamarine:        #5DE3B0;
$aquamarine-light:  #C7EDDF;

$gold:              #FFA322;
$gold-dark:         #C87A0C;
$gold-light:        #FFECD2;

$coral-dark:        #783B3D;
$coral:             #E57173;
$coral-light:       #FCE4E4;

$purple-dark:       #2F4172;
$purple:            #4C70D0;
$purple-light:      #BECDF4;
$purple-lighter:    #EFF3FD;
$pinky-purple:      #F9F0F9; // used only once on NextSummative

$blue-dark:         #0271C0;
$blue:              #0D99FD;
$blue-light:        #9ED5FC;
$blue-lighter:      #D9EFFE; // formerly $sky-blue-lighter, not in figma, used ~7 times

$gray-dark:         #656E7B;
$gray:              #BCC1C8;
$gray-light:        #F2F3F4;
// L8y colors
$l8y-red:           #DD002F;

/**
 * Study Path Colors
 *
 * Get a particular study path color with SASS maps, if you want test.dark do: `get("test.dark", $study-path-color);`
 **/
$study-path-color: (
  "review": (
    "dark": $coral-dark,
    "background": $coral,
    "lighter": $coral-light,
    "text": $white,
  ),
  "prep": (
    "dark": $blue-dark,
    "background": $blue-light,
    "lighter": $blue-lighter,
    "text": $black,
  ),
  "test": (
    "dark": $purple-dark,
    "background": $purple,
    "lighter": $purple-light,
    "text": $white,
  ),
);

$get-default-map: (
  "study-path": $study-path-color,
  "assess-type": (
    "preclass": $aquamarine,
    "homework": $coral,
    "prep": $blue-light,
    "practicetest": $purple,
    "summative": $purple-dark,
  ),
) !default;

$button-default-color: $purple;
$button-default-color-hover: $purple-dark;
$button-default-color-active: $gray-dark;
$button-default-color-disabled: $gray-dark;
$button-default-color-clicked: $purple-light;

$summary-bar-stats: $purple-dark;
$summary-bar-text: $gray-dark;
$study-box: $blue-lighter;

$class-materials: $gold;

//other concept colors
$success:       $green;
$muddy-color: $blue;
$muddy-background: $blue-lighter;
$recapture-color: $coral;
$recapture-background: $coral-light;

$non-class-day-color: $coral-light;
$non-class-day-color-active: $coral;
$class-day-label-color: $purple;

$border-radius-sm: 7px;
$border-radius: 10px;
$border-radius-lg: 15px;
$border-color: rgba(121, 132, 146, 0.3);
$box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
$menu-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;
$font-size-h4: 24px;
$font-size-h3: 30px;
$font-size-h2: 42px;
$font-size-h1: 72px;
$tooltip-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
$standard-font: "Open Sans", sans-serif;
$monospace-font: "Courier New", monospace;
$modal-overlay-background: rgba(0, 0, 0, 0.5);
$confirmation-overlay-background: rgba(0, 0, 0, 0.4);

// export colors for use in js
:export {
  white: $white;
  black: $black;
  red: $red;
  redDark: $red-dark;
  redLight: $red-light;
  aquamarine: $aquamarine;
  aquamarineDark: $aquamarine-dark;
  aquamarineLight: $aquamarine-light;
  green: $green;
  greenDark: $green-dark;
  greenLight: $green-light;
  gold: $gold;
  goldDark: $gold-dark;
  goldLight: $gold-light;
  coralDark: $coral-dark;
  coral: $coral;
  coralLight: $coral-light;
  purpleDark: $purple-dark;
  purple: $purple;
  purpleLight: $purple-light;
  purpleLighter: $purple-lighter;
  blueDark: $blue-dark;
  blue: $blue;
  blueLight: $blue-light;
  blueLighter: $blue-lighter;
  grayDark: $gray-dark;
  gray: $gray;
  grayLight: $gray-light;
  // ===
  topicsReviewed: $coral;
  coral: $coral;
  prep: $blue-light;
  practiceTest: $purple;
}
