@import "~sass-includes/index";

.error-boundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .error-boundary__content {
    display: inherit;
    flex-direction: inherit;
    justify-content: center;
    align-items: center;
    background-color: $blue-lighter;
    padding: 30px;
    margin-top: 30px;
    border-radius: 30px;
    max-width: 60%;
    .error-boundary__message {
      line-height: 30px;
      text-align: center;
    }
  }
  .pig-pen {
    margin-top: 24px;
    width: 256px;
    height: 256px;
  }
  .show-debug-info {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    button {
      font-size: $font-size-xxs;
    }
    textarea.more-info {
      width: 500px;
      height: 100px;
    }
  }
}
