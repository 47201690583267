@import "~sass-includes/index";
.course-list {
  margin-top: 30px;
}

.course-list__wrapper {
  @include centered($max-width);
  .course-list__top-wrapper {
    display: flex;
    align-items: center;
  }
  .course-list__header-title {
    font-weight: bold;
    margin-bottom: 12px;
  }
}

.course-list__courses {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -10px;
}

.course-list__course {
  position: relative;
  margin: 0 10px;
  border: 1px solid $gray;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.07);
  transition: all 400ms $ease-out-quint;
  margin-bottom: 20px;
  &:hover {
    transform: translate3d(0, -10px, 0);
  }
}

.course-list__image {
  width: 100%;
  padding-bottom: 45%;
  background-size: cover;
}

.course-list__content {
  padding: 20px 15px;
  padding-bottom: 30px;
}

.course-list__title {
  margin-top: 0;
  padding-bottom: 8px;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 600;
  color: $black;
  font-size: 1.17em;
}

.course-list__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
}

.course-list__number {
  color: $gray-dark;
  text-transform: uppercase;
  font-size: $font-size-sm;
}

.course-list__coinst-icon {
  margin-right: 25px;
  z-index: $zindexStandardTooltip;
  svg {
    height: 22px;
    width: 22px;
    color: $gray-dark;
  }

}

.course-list__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: all 300ms $ease-out-quint;
  .course-list__course:hover & {
    visibility: visible;
    opacity: 1;
  }
}

.course-list__button-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .course-list__button {
    border: 1px solid $white;
    padding: 10px 15px;
    border-radius: 20px;
    color: $white;
    text-decoration: none;
    &:hover {
      color: $black;
      background-color: $white;
    }
    transform: translate3d(0, 10px, 0);
    transition: all 300ms $ease-out-quint;
    .course-list__course:hover & {
      transform: translate3d(0, 0, 0);
    }
  }
}
