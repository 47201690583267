@import "~sass-includes/index";

.clarity-radio-group {
  display: flex;
  flex-direction: row;
  .clarity-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $standard-font;
    font-weight: bold;
    font-size: $font-size-xs;
    line-height: 16px;
    border-radius: 10px;
    margin: 0 3px;
    padding: 6px;
    min-width: 80px;

    background: none;
    border: 1px solid transparent;
    color: $black;
    svg {
      fill: $black;
    }
    &:hover, &:focus-within {
      border: 1px solid $purple-light;
      color: $purple;
      svg {
        fill: $purple;
      }
    }
    &[data-selected="true"] {
      border: 1px solid $purple-light;
      background-color: $purple-light;
      color: $purple-dark;
      svg {
        fill: $purple-dark;
      }
      &:focus-within {
        border: 1px solid $purple;
      }
    }
    &:disabled, &[data-disabled="true"] {
      color: $gray-dark;
      background-color: $gray-light;
      svg {
        fill: $gray-dark;
      }
      &:hover, &:focus-within {
        border: 1px solid transparent;
      }
    }
  }
}
