@import "~sass-includes/index";

.score-book-container {
  margin: 15px;
  button {
    &:hover {
      background-color: $coral-light;
    }
  }
  .score-book-container__assessment-name {}
  .score-book-container__assessment-dueDate {
    color: $black;
    font-size: $font-size-xs;
    margin-top: 4px;
  }

  .score-book-container__body {
    margin-top: 15px;
    width: 100%;
    .score-book-container__assessment-grid-table-wrapper {
      height: calc(100vh - 120px);
      overflow-y: auto;
    }

    table.student-scores-container__table {
      border-collapse: collapse;
      font-family: $standard-font;
      border: 1px solid;
      border-color: $gray-light;
      thead {
        background-color: $gray;
        text-align: left;
        min-height: 61px;
        vertical-align: bottom;
        th {
          background-color: $gray;
          position: sticky;
          top: 0;
          padding: 4px 0px 4px 4px;
          &.score-book-container__header-response {
            width: 20%;
          }
          &.score-book-container__header-correct-clarity {
            width: 10%;
          }
        }
        .score-book-container__header-question {
          vertical-align: top;
        }
      }
    }
    tbody {
      tr {
        &:hover {
          background-color: $purple-light;
        }
      }
    }
    th {
      font-size: 13px;
      font-weight: normal;
      border-color: $gray;
      padding-right: 3px;
    }
    tr {
      height: 30px;
      &:nth-child(even) {
        background-color: $gray-light;
      }
      &.first-row {
        height: 10px;
      }
      &.spacer-row {
        height: 20px;
        border-top: 1px solid;
        border-color: $gray-light;
      }
    }
  }
  td {
    padding: 4px 0px 4px 4px;
    text-align: left;
    font-size: 13px;
    font-weight: normal;
    border: 1px solid;
    border-color: $gray-light;
    >a:link {
      @extend %standardLink;
      font-size: 13px;
    }
    >a:visited {
      @extend %standardLink;
      font-size: 13px;
    }
    &.icon {
      text-align: right;
      svg {
        fill: $recapture-color;
      }
    }
    &.unattempted-and-late {
      background-color: $coral-light;
    }
  }
}

.score-book-container__title-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: $standard-font;
  font-size: $font-size-md;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: $gray;
  .score-book-container__title-bar-link {
    @extend %standardLink;
    font-weight: 700;
  }
  .score-book-container__header-bar__info {
    margin-left: 20px;
  }
}

.score-book-container__header {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  padding: 4px 0px;
  text-align: left;
  button.score-book-container__header__preview-button {
    text-align: left;
  }
}

.score-book-container__correct-response-bar {
  background-color: $gray-light;
  padding: 3px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.score-book-container__mini-icon {
  @include instructor-mini-icon;
  &:hover {
    background-color: $blue-light;
  }
  margin-left: 4px;
}

.score-book-container__table-row-inactive {
  color: $gray-dark;
  font-size: $font-size-xs;
}

.score-book-container__data-row {
  display: flex;
}

.score-book-container__button-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .student-scores__download-button {
    margin-right: 10px;
  }
}
.score-book-container__header-bar__info {
  .better-tooltip__trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: underline;
    svg {
      margin-left: 4px;
    }
  }
}
.score-book-container__header-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  background-color: $white;
  .student-scores-table__navigation {
    padding: 4px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
      font-size: $font-size-sm;
    }
    input {
      width: 50px;
      text-align: center;
      margin-left: 4px;
    }
    .student-scores-table__navigation-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $gray-dark;
      .min-max {
        width: 44px;
        font-size: $font-size-sm;
        text-align: center;
      }
      > button {
        padding: 10px 16px;
      }
    }
  }
}

// style the big main student scores table
.student-scores-table__wrap {
  height: calc(100vh - 120px);
  overflow-y: auto;
  table {
    border-collapse: collapse;
    min-width: 80%;
    thead tr th {
      position: sticky;
      top: 0;
    }
    th {
      background-color: $gray;
      text-align: left;
      min-height: 61px;
      vertical-align: bottom;
      button {
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .assessmentName {
          padding: 2px 4px;
          color: $purple-dark;
        }
        .assessmentPoints {
          background-color: $gray-light;
          border-radius: 4px;
          padding: 4px;
          width: 100%;
        }
      }
    }
    tr {
      width: 100%;
      box-sizing: border-box;
    }
    td {
      padding-left: 4px;
    }
    .score-book-container__header-student-name {
      overflow: hidden;
    }
    .student-score-cell__student-name {
      text-align: left;
      border: none;
    }
    .student-score-cell__score {
      padding-left: 10px;
    }
  }
}

.points-and-completion {
  width: 100%;
  display: flex;
  flex-direction: column;
}
