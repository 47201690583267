@import "~sass-includes/index";

$header-padding: 16px;
$dogear-width: 14px;

.daily-card {
  @extend %cardstyle;
  margin-bottom: 20px;

  .daily-card__row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .daily-card__header {
    .daily-card__action-popup-wrap {
      position: absolute;
      right: $header-padding;
      top: 52px;
      z-index: 100;
      ul, .daily-card__action-popup {
        @extend %list-reset;
        overflow: hidden;
        border-radius: $border-radius-sm;
        box-shadow: $box-shadow;
        background-color: $white;
        position: static;
        z-index: 100;
        li > a {
          svg {
            display: none;
          }
          font-weight: 600;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px 16px;
          &:hover {
            background-color: $gray;
          }
        }
      }
    }

    .daily-card__column-label {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-weight: 400;
      font-size: $font-size-xs;
      line-height: 16px;
    }
    align-items: baseline;
    position: relative;
    background-color: $gray-light;
    padding: 23px $header-padding 8px;
    color: $black;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
    h3.daily-card__header-title {
      @extend %hreset;
      font-weight: 600;
      font-size: $font-size-xl;
      line-height: 27px;
    }
    &.hide-column-labels {
      .daily-card__column-label {
        visibility: hidden;
      }
    }
    .daily-card__action {
      position: absolute;
      top: $header-padding;
      right: $header-padding;
      display: flex;
      flex-direction: row;
      align-items: center;
      a, button {
        background-color: $white;
        padding: 4px 8px;
        border-radius: 25px;
        border: 1px solid $gray;
        font-size: $font-size-sm;
        font-size: inherit;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $black;
      }
      .daily-card__action-button-label {
        margin-left: 8px;
        font-weight: 400;
      }
    }
  }

  &.empty-card {
    .daily-card__header {
      align-items: inherit;
      padding: $header-padding;
      border-radius: $border-radius-sm;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .daily-card__action {
        position: relative;
        top: inherit;
        right: inherit;
      }
    }
  }
  &.labeled-class-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 100px;
    padding-left: $dogear-width;
    .labeled-class-card__icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 24px 0 16px;
    }
    .labeled-class-card__label {
      margin-bottom: 10px;
      font-size: $font-size-h4;
      color: $black;
    }
    .labeled-class-card__message {
    }

  }

  &[data-cardtype="lo-card"] {
    .daily-card__header {
      border-left: $dogear-width solid $gray-dark;
    }
    .daily-card__action {
      svg {
        fill: $gray-dark;
      }
    }
  }
  &[data-cardtype="homework"] {
    .daily-card__header {
      border-left: $dogear-width solid get("assess-type.homework");
    }
    .daily-card__action {
      svg {
        fill: get("assess-type.homework");
      }
    }
  }
  &[data-cardtype="preclass"] {
    .daily-card__header {
      border-left: $dogear-width solid get("assess-type.preclass");
    }
    .daily-card__action {
      svg {
        fill: get("assess-type.preclass");
      }
    }
  }
  &[data-cardtype="summative"] {
    .daily-card__header {
      border-left: $dogear-width solid $purple-dark;
    }
    .daily-card__action {
      svg {
        fill: $purple-dark;
      }
    }
  }
  &[data-cardtype="ic"] {
    .daily-card__header {
      border-left: $dogear-width solid $class-materials;
    }
    .daily-card__action {
      svg {
        fill: $class-materials;
      }
    }

  }
  &[data-instructiontype="ooc"] {
    .daily-card__header {}
  }
  .daily-card__body {
    font-size: $font-size-sm;
    border-left: $dogear-width solid transparent;
    padding: 24px $header-padding 36px 0;
    @media #{$breakpoint-sm-up} {
      padding: 24px $header-padding 36px;
    }
    .daily-card__row, .daily-planner__item-row {
      min-height: 32px;
      a {
        font-weight: 600;
        color: $purple-dark;
      }
      &[data-published="no"] {
        color: $gray-dark;
        a {
          color: $gray-dark;
        }
        .daily-planner__item-status {
          font-style: italic;
        }
      }
      font-size: $font-size-sm;
      .daily-card__item-column {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: $font-size-sm;
        text-align: left;
        justify-content: flex-start;
        .class-session-card-recap-icon {
          @extend %flex-centered;
          margin: auto;
          width: 28px;
          height: 28px;
          svg {
            fill: $recapture-color;
          }
        }
      }
    }
  }
}
