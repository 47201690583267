@import "~sass-includes/index";

textarea {
  font-family: "Courier New", monospace;
}

.practicetest-assessment {
  width: 100%;
  height: 100%;
  margin-top: 35px;
}

.demo-assessment__footer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.practicetest-assessment__header-name {
  font-family: $standard-font;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-h3;
  line-height: 41px;
}

.lrn.lrn_widget.lrn_qr.lrn_mcq {
  font-family: $standard-font;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-sm;
  line-height: 140%;
}

.lrn-overview {
}
.lrn-assess-content {
  @extend .codon-card;
}

.practicetest-assessment__infotip {
  background-color: $gray;
  display: flex;
  flex-direction: row;
  padding: 24px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.practicetest-assessment__infotip-text {
  font-style: italic;
  font-weight: 600;
  font-size: $font-size-sm;
  line-height: 140%;
}
.practicetest-assessment__box {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  width: 100%;
  margin-bottom: 18px;
}
.practicetest-left {
  width: 65px;
}
.practicetest-assessment__infotip-icon {
  margin-right: 20px;
}
.practicetest-right {
  width: 100%;
}

.practicetest-assessment__text {
  padding: 50px 55px 30px;
  .practicetest-assessment__text-title {
    font-family: $standard-font;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-lg;
    line-height: 140%;
  }
}

.practicetest-assessment__action {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 55px;
  width: 100%;
  div {
    width: 50%;
    justify-content: center;
    text-align: center;
    i {
      font-size: $font-size-sm;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;

    }
  }
}
