@import "~sass-includes/index";

$icon-size: 16px;
$item-height: 64px;
$item-padding: 4px;

.readiness-experience-wrap {
  background-color: $purple-lighter;
  width: 100%;
  height: 100%;

  .assessment-taker__header__lo-number {}
  .assessment-wrap {
    .assessment-taker__header {
      box-shadow: $box-shadow;
    }
  }
}
.study-path__readiness-experience {
  padding: 0;
  @media #{$breakpoint-sm-up} {
    padding: 16px 44px;
  }
  .assessment-taker__header {
    background-color: $purple-lighter;
  }
}
.assessment-wrap {
  .assessment-taker__header {
    &[data-showheader="false"] {
      display: none;
    }
    border: 1px solid $gray;
    position: relative;
    .toggle-lo-button {
      position: absolute;
      top: 16px;
      right: 16px;
      text-decoration: underline;
      @media #{$breakpoint-xs-only} {
        right: 50px;
        top: 20px;
      }
    }
    .topic-with-los {
      &[data-showtopic="false"] {
        display: none;
      }
    }
    .assessment-taker__header__lo-item {
      &[data-showlo="false"] {
        display: none;
      }
    }
    &[data-collapsed="true"] {
      .topic-with-los {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .collapsed-topic-title {
        font-weight: bold;
        margin-right: 12px;
      }
      .collapsed-lo-number {
        margin-right: 8px;
      }
    }
  }
}

.assessment-taker-root.assessment-taker-readiness-experience {
  @include centered($max-width);
  width: 100%;
  min-height: 100vh;
  .lrn-assess {
    background-color: transparent;
  }
  .assessment-taker__header {
    background-color: $white;
  }
  .row.at-action-bar.action-bar-REX {
    @include centered($max-width);
    background: $white;
    border: 2px solid $border-color;
    .clarity-button {
      color: $black;
      svg {
        fill: $black;
      }
    }
    .at-action-bar__nav-text {
      color: $black;
    }
  }
  .assessment-intro-outro__infotip {
    background-color: $purple-light;
    border-radius: $border-radius;
    margin-bottom: 0px;
  }
}

.learnosity-item__wrap {
  .readiness-nav-menu__wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media #{$breakpoint-sm-up} {
      padding-left: 16px;
    }
  }
}

%nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  background-color: $white;
  border-bottom: 1px solid $black;
  border-left: 1px solid $gray-dark;
  border-right: 1px solid $gray-dark;
  width: 100%;
}

.readiness-nav-menu {
  width: 100%;
  @media #{$breakpoint-xs-only} {
    margin-bottom: 16px;
  }
  .rex-nav-item__about-link {
    @extend %nav-item;
    svg {
      margin-right: 10px;
    }
    text-align: left;
    font-size: 14px;
    padding: 8px;
  }
  ul {
    @extend %list-reset;
    li {
      @extend %list-reset;
      button {
        @extend %button-reset;
        @extend %nav-item;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        &[data-active="true"] {
          background-color: $blue-lighter;
        }
        .rex-nav-item__status {
          margin: 4px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          > .rex-nav-item__status-icon {
            @extend %flex-centered;
            &[data-show="false"] {
              visibility: hidden;
            }
            padding: $item-padding 0;
            width: 32px;
            svg {
              width: $icon-size;
              height: $icon-size;
            }
            text-align: center;
          }
        }
        .rex-nav-item__content {
          height: $item-height;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: $item-padding 0;
          width: 100%;
          .rex-nav-item__title {
            font-size: 14px;
          }
          .rex-nav-item__lo-list {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            font-size: 14px;
            margin-bottom: 4px;
            margin-right: 4px;
            .rex-nav-item__lo-item {
              @extend %lo-number-pill;
              padding-left: 4px;
            }
          }
        }
      }
    }
  }
}

.rex-nav-item__header, .rex-nav-item__footer {
  background-color: $purple-dark;
  padding: 8px;
  color: $black;
  border-right: 1px solid $black;
  border-left: 1px solid $black;
  width: 100%;
}
.rex-nav-item__header {
  border-radius: $border-radius $border-radius 0 0;
  border-bottom: 1px solid $black;
  border-top: 1px solid $black;
}
.rex-nav-item__footer {
  border-radius: 0 0 $border-radius $border-radius;
  border-bottom: 1px solid $black;
  border-left: 1px solid $black;
}

.rex-nav-item__lo-item__tooltip {
  width: 300px;
}
