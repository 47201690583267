@import "~sass-includes/index";
$base-padding: 16px;

$left-gutter-width: 33px;
$info-column-width: 33px;

.better-course-planner {
  margin-top: 30px;
  transition: transform 150ms linear;
  height: 100%;
  @include centered($max-width);
  .bcp__toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .bcp__toolbar__left {
      margin-left: $base-padding;
      font-size: $font-size-sm;
    }
    .bcp__toolbar__right {
      padding: 12px 0;
      &:not(:last-child) {
        margin-right: 8px;
      }
      .bcp__toolbar__item__expand-collapse-weeks {
        font-size: $font-size-sm;
      }
      .toggle-switch {
        flex-direction: row-reverse;
        .toggle-switch-labels {
          margin-right: 6px;
        }
      }
    }
  }
  .bcp__expando-caret {
    padding: 0 4px;
    font-size: $font-size-sm;
    svg {
      transform: rotate(-90deg);
    }
    &[data-expanded="true"] {
      svg {
        transform: rotate(-180deg);
      }
    }
    &:disabled {
      svg {
        color: $gray;
      }
    }
  }
  .bcp__week-list {
    border-bottom: 100px solid transparent;
    .bcp__week {
      svg {
        transition: transform 150ms linear;
      }
      &[data-expanded="false"] {
        .bcp__week-header {
          background-color: $gray-light;
          border: 1px solid $gray;
          color: $blue;
        }
        .bcp__week-body {
          display: none;
        }
        .bcp__week-header__action {
        }
        .bcp__week-header__topic-list {
          font-size: $font-size-md;
        }
      }
      .bcp__week-header {
        background-color: $gray-dark;
        border-radius: 5px;
        color: $white;
        padding: 14px 28px;
        justify-content: space-between;
        .bcp__week-header__title {
          font-weight: 600;
          font-size: $font-size-lg;
          line-height: 27px;
          text-align: left;
        }
        .bcp__week-header__topic-list {
          line-height: 27px;
          color: $black;
          text-align: left;
        }
        .bcp__week-header__action {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          svg {
            margin-left: 8px;
          }
        }
      }
      .bcp__week-body {
        .bcp__week-body__classes {
        }
      }
      &[data-bcp-view-mode-week="compact"] {
        .bcp__week-header {
          border-radius: 0;
          padding: 4px 12px 4px $base-padding;
          align-items: center;
          justify-content: space-between;
          .bcp__week-header__title {
            font-size: $font-size-md;
            line-height: inherit;
          }
        }
      }
    }
  }
  .bcp__action-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: normal;
    svg {
      margin-right: 4px;
    }
    &.class-action-button {
      color: $blue;
      svg {
        fill: $blue;
      }
    }
    &.topic-action-button {
      color: $gray-dark;
      svg {
        margin-right: 8px;
        fill: $gray-dark;
      }
    }
  }
  .bcp__cancel-moving-topic {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    .bcp__cancel-moving-topic__container {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: $border-radius-lg $border-radius-lg 0 0;
      padding: 8px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .bcp__cancel-moving-topic__message {
        text-align: center;
        margin-bottom: 8px;
      }
    }
    button {
      pointer-events: auto;
      background-color: $red;
      &:hover {
        background-color: $red-dark;
      }
    }
  }
}

.bcp__class-row__card__lo__alignment {
  .alignment-item__list {
    .alignment-item__list-item {
      display: flex;
      flex-direction: row;
      min-height: $item-height;
      width: 100%;
      padding-left: 24px;
      margin-bottom: $base-padding;
      &:first-child {
        margin-top: $base-padding;
      }
      .alignment-item__list-item-indicator {
        @extend %alignment-item__rectangle;
      }
      .alignment-item__list-item-details {
        padding: 0px $base-padding;
        font-size: $font-size-sm;
        .alignment-item__list-item-assessType {
          color: $true-black;
          font-weight: 600;
          font-size: $font-size-sm;
          line-height: 19px;
          margin-bottom: 4px;
        }
        .alignment-item__list-item-info {
          color: $true-black;
          font-weight: 400;
          font-size: $font-size-sm;
          line-height: 18px;
          > div {
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}
.bcp__modal {
  .modal-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.bcp__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .bcp__modal__content {
    min-height: 300px;
  }
  .bcp__modal__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button.button {
      margin-bottom: 20px;
    }
    button.button.primary {
      width: calc(100% - 110px);
    }
    button.button.secondary {
      width: calc(100% - 110px);
    }
    button.text-button {
      color: $blue;
      font-weight: 600;
      font-size: $font-size-sm;
    }
  }
}
