@import "~sass-includes/index";
.better-timeline {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 30px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
  border: $gray;
  .better-timeline__chevron-btn-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    &.better-timeline__decrement-wrap {
      left: -36px;
    }
    &.better-timeline__increment-wrap {
      right: -36px;
    }
    .better-timeline__chevron-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
      border: 1px solid $gray;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      &:disabled {
        visibility: hidden;
      }
    }
  }
}
.better-timeline__view {
  position: relative;
  overflow: hidden;
}
.better-timeline__content {
  &.enable-transition {
    transition: all 300ms $ease-out-quint;
  }
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 300px;
  .better-timeline__day-wrap {
    text-align: center;
    .better-timeline__header {
      &[data-istoday=false] {
        border-top: 1px solid transparent;
        border-color: transparent;
      }
      &[data-istoday=true] {
        @extend %today-indicate;
        border-radius: $border-radius $border-radius 0 0;
        border-bottom: 0;
        font-weight: bold;
        .better-timeline__weekday {
          font-weight: bold;
        }
      }
      position: relative;
      padding-top: 8px;
      .better-timeline__weekday {
        text-transform: uppercase;
        font-size: 11px;
        display: block;
        text-align: center;
        font-size: $font-size-xxs;
        font-weight: 600;
        color: $black;
      }
      .better-timeline__date {
        margin-top: 5px;
        border-bottom: 1px solid $gray;
        padding-bottom: 10px;
        font-size: $font-size-xxs;
        text-align: center;
        color: $black;
      }
    }
    $class-btn-height: 37px;
    &[data-isclassday="false"] {
      .better-timeline__day-body {
        border-top: $class-btn-height solid transparent;
      }
    }
    .better-timeline__day-body {
      border-top: 1px solid $gray;
      border-left: 1px solid $gray;
      border-right: 1px solid $gray;
      height: 100%;
      .better-timeline__class-session-header {
        width: 100%;
        border-bottom: 2px solid $gray;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-xs;
        font-weight: 600;
        color: $black;
        &.better-timeline__class-session-header-button {
          height: $class-btn-height;
          background-color: $blue-lighter;
          &:not(:disabled) {
            &:hover {
              background-color: $purple-light;
            }
          }
          &:disabled {
            cursor: default;
            background-color: $gray;
            &.is-active {
              background-color: $blue;
            }
          }
          &[data-isselected=true] {
            background-color: $blue;
            color: $white;
            font-weight: bold;
          }
        }
        &.better-timeline__class-session-header-non-class-button {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          overflow: hidden;
          min-height: 80px;
          background-color: $non-class-day-color;
          &:not(:disabled) {
            &:hover {
              background-color: $non-class-day-color-active;
            }
          }
          &:disabled {
            cursor: default;
          }
          &[data-isselected=true] {
            background-color: $non-class-day-color-active;
            color: $white;
            font-weight: bold;
          }
          .better-timeline__class-session-header-non-class-label {
            margin: auto;
          }
        }
      }
    }
  }
  .better-timeline__pill-stack {
    padding-top: 4px;
    .better-timeline__pill {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // these breakpoints make the text full-size either when only 1 week shows or screen is wide enough
      // when it gets scrunched up is when it is narrow but not narrow enough to only show 1 week, but should still be readable
      font-size: $font-size-xs;
      @media #{$breakpoint-sm-up} {
        font-size: 8px;
      }
      @media #{$breakpoint-md-up} {
        font-size: $font-size-xs;
      }
      border-radius: 20px;
      margin: 4px;
      min-height: 24px;
      &.topic-pill {
        background-color: $gray-dark;
        color: $white;
        font-weight: 600;
        border-radius: 12px;
        padding: 4px;
        text-overflow: ellipsis;
        cursor: default;
      }
      &.assessment-pill {
        color: $white;
        &[data-assesstype="readiness"],
        &[data-assesstype="preclass"] {
          background-color: get("assess-type.preclass");
          color: $black;
          &[data-disabled="true"] {
            background-color: lighten(get("assess-type.preclass"), 24%);
            color: $gray-dark;
          }
        }
        &[data-assesstype="homework"] {
          background-color: get("assess-type.homework");
          &[data-disabled="true"] {
            background-color: lighten(get("assess-type.homework"), 24%);
            color: $gray-dark;
          }
        }
        &[data-assesstype="summative"] {
          background-color: get("assess-type.summative");
          &[data-disabled="true"] {
            background-color: lighten(get("assess-type.summative"), 45%);
          }
        }
        &[data-published="no"] {
          background-color: $gray;
          color: $black;
        }
      }
    }
    .better-timeline__class-session-header__day-label-pill {
      text-align: center;
      overflow: hidden;
      padding: 4px 0 6px;
      font-size: $font-size-xs;
      background-color: $class-day-label-color;
      color: $white;
      font-weight: 600;
      width: 100%;
    }
  }
}

// AssessmentPill tooltip
.better-timeline__assessment-tooltip-wrap {
  &.better-tooltip__container {
    padding: 12px;
    max-width: 220px;
  }
  .better-timeline__assessment-tooltip {
    > div {
      line-height: 20px;
    }
    .better-timeline__assessment-tooltip-name {
      margin-bottom: 8px;
      font-weight: bold;
    }
    .better-timeline__assessment-tooltip-date {
      line-height: 20px;
    }
  }
}
.instructor-assessment-pill.better-tooltip__container, .student-assessment-pill.better-tooltip__container {
  padding: 0;
  overflow: hidden;
  min-width: 200px;
  max-width: 300px;
  .better-timeline__assessment-tooltip {
    &[data-assesstype="readiness"],
    &[data-assesstype="preclass"] {
      .better-timeline__assessment-tooltip-name {
        background-color: get("assess-type.preclass");
        color: $black;
      }
      &[data-opentostudents="false"] {
        .better-timeline__assessment-tooltip-name {
          background-color: lighten(get("assess-type.preclass"), 24%);
          color: $gray-dark;
        }
      }
    }
    &[data-assesstype="homework"] {
      .better-timeline__assessment-tooltip-name {
        background-color: get("assess-type.homework");
        color: $white;
      }
      &[data-opentostudents="false"] {
        .better-timeline__assessment-tooltip-name {
          background-color: lighten(get("assess-type.homework"), 24%);
          color: $gray-dark;
        }
      }
    }
    &[data-assesstype="summative"] {
      .better-timeline__assessment-tooltip-name {
        background-color: get("assess-type.summative");
        color: $white;
      }
      &[data-opentostudents="false"] {
        .better-timeline__assessment-tooltip-name {
          background-color: lighten(get("assess-type.summative"), 45%);
        }
      }
    }
  }
  .better-timeline__assessment-tooltip {
    display: flex;
    flex-direction: column;
    .better-timeline__assessment-tooltip-name {
      padding: 8px 12px;
      margin-bottom: 0;
    }
    .better-timeline__assessment-tooltip-status {
      background-color: $gray;
      line-height: 14px;
      font-size: smaller;
      text-align: center;
      &[data-published="no"] {
        background-color: $gray;
        color: $white;
      }
    }
    .better-timeline__assessment-tooltip-body {
      padding: 0 8px 8px;
      margin-top: 4px;
      .better-timeline__assessment-tooltip-dates__wrap {
        .better-timeline__assessment-tooltip-date {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    .better-timeline__assessment-tooltip-lo-pills {
      .lo-pill {
        margin-right: 8px;
        white-space: nowrap;
      }
    }
  }
}
.student-assessment-pill {
  position: relative;
  .pill-status-icon {
    position: absolute;
    right: 1px;
    top: -4px;
    background-color: $white;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    svg {
      position: absolute;
      top: 1px;
      right: 1px;
      width: 12px;
      height: 12px;
      color: $black;
    }
  }
}

// TopicPill tooltip
.topic-pill-tooltip__content {
  display: flex;
  flex-direction: column;
  .topic-pill-tooltip__header {
    padding-bottom: 8px;
  }
  .topic-pill-tooltip__body {
    .topic-pill-tooltip__lo-list {
      @extend %lo-number-list;
      .topic-pill-tooltip__lo-list-item {
        @extend %noselect;
        @extend %lo-number-pill;
        cursor: default;
        background-color: $gray-dark;
        color: $white;
        font-weight: 600;
        padding: 4px 6px;
        margin-right: 4px;
        border-radius: $border-radius;
      }
    }
  }
}
