@import "~sass-includes/index";

$h-padding: 54px;

#learnosity-wrap {
  @media #{$breakpoint-xs-only} {
    // 90vh to account for header height
    max-height: 90vh;
  }
}
.assessment-taker-root .lrn-assess {
  min-height: 460px;
}

.row.learnosity-row {
  // put navmenu above question on mobile
  flex-direction: column-reverse;
  @media #{$breakpoint-sm-up} {
    flex-direction: row;
  }
}

.learnosity-box {
  .lrn.lrn_widget.lrn_qr.lrn_mcq {
    font-family: $standard-font;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-sm;
    line-height: 140%;
  }

  div.learnosity-item div.lrn-assess-content.content.lrn {
    background-color: $white;
    /* Card border */
    border: 1px solid $border-color;
    /* Card */
    box-shadow: $box-shadow;
    border-radius: 10px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 2.5rem !important;
    overflow: hidden;
  }

  .lrn .lrn_widget {
    /* default padding for passages, videos, etc */
    padding: 12px 24px;
    @media #{$breakpoint-md-up} {
      padding: 12px $h-padding;
    }
  }

  .slides-vertical-pagination {
  }

  li.lrn-assess-li {
    min-height: 60px;
  }

  .lrn.lrn-assess.lrn-md .lrn-right-region .pagination-item a {
    padding-left: 48px !important;
    font-family: $standard-font;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-sm;
    line-height: 140%;
  }
  .lrn.lrn-assess .lrn-right-region .right-wrapper.lrn-right-region-verticaltoc-standalone {
    background-color: $white;
  }
  .lrn.lrn-assess .lrn-right-region .right-wrapper.lrn-right-region-verticaltoc-standalone .lrn-vertical-toc {
    border-radius: 10px;
    overflow: hidden;
  }

  .lrn.lrn-assess .lrn-vertical-toc .pagination-active:after, .lrn.lrn-assess .lrn-vertical-toc .pagination-active a:after {
    display: none;
  }
  .lrn.lrn-assess .lrn-vertical-toc .pagination-active:before, .lrn.lrn-assess .lrn-vertical-toc .pagination-active a:before {
    display: none;
  }

  .lrn.lrn-assess .lrn-vertical-toc .pagination-item.pagination-active {
    background-color: $gray-light;
    box-shadow: inset 0px -1px 0px $gray-dark;
  }
  .lrn.lrn-assess .lrn-vertical-toc .pagination-item.pagination-active {
    border-top: 0;
  }
}

.lrn-assess-btn {
  display: flex;
  flex-direction: row;
}

.learnosity-item.lrn-assess {
  margin: 0;
}

.learnosity-item__trigger-validate-button {
  background: $gray-dark;
  border-radius: 20px;
  color: $white;
  padding: 8px 33px;
  border: none;
  margin-top: 40px;
}
.overlay__interactive {
  max-width: 500px;
}
.overlay__explain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 55%;
  font-family: $standard-font;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  font-size: $font-size-sm;
  .overlay__explain-subtitle {
    font-size: $font-size-xxs;
  }
}
.overlay__muddy-clear {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.lrn_distractor_rationale_list {
  border-radius: 10px;
  overflow: hidden;
}
.lrn_validation_stem_numeration {
  display: none;
}
/* Hide default l8y check answer button */
.lrn_response button.lrn_validate {
  display: none !important;
}
.lrn code, .lrn kbd, .lrn pre, .lrn samp {
  font-family: Courier, monospace !important;
}
/* Label Image Drag & Drop questions */
.lrn_imageclozeassociationV2 .lrn_validatedResponseList {
  display: none;
}
/* OR questions */
.lrn_response_input_wrapper.lrn_complete {
  border-color: $gray-dark !important; // TODO gross important
  border-top-width: 0.35714em;
  border-radius: 0;
}
.lrn_response_input_wrapper.lrn_complete:after {
  background-color: $gray-dark !important; // TODO gross important
}
.lrn_distractor_rationale_wrapper .lrn_distractor_rationale_list.lrn_distractor_rationale_correct.lrn_distractor_rationale_complete {
  border: solid 1px $blue-dark;
  .lrn_distractor_rationale_list_title {
    background-color: $blue-lighter;
    color: $blue-dark;
    border-left: solid 4px $blue-dark;
  }
}
.lrn_distractor_rationale_wrapper .lrn_distractor_rationale_list.lrn_distractor_rationale_correct.lrn_distractor_rationale_complete:before {
  color: $blue-dark;
}
.lrn_distractor_rationale_wrapper .lrn_distractor_rationale_list.lrn_distractor_rationale_correct.lrn_distractor_rationale_first {
  border: solid 1px $gold-dark;
  .lrn_distractor_rationale_list_title {
    background-color: $gold-light;
    color: $gold-dark;
    border-left: solid 4px $gold-dark;
  }
}
.lrn_distractor_rationale_wrapper .lrn_distractor_rationale_list.lrn_distractor_rationale_correct.lrn_distractor_rationale_first:before {
  display: none;
}

.overlay__study-path {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* parent of l8y container within *AssessmentTaker components, pad for actionbar */
.assessment-wrap {

  @media #{$breakpoint-sm-up} {
    padding-bottom: 100px;
  }
}
.assessment-wrap .lrn sub, .assessment-wrap .lrn sup {
  font-size: 85% !important;
}

.assessment-wrap .lrn pre {
  font-size: $font-size-md;
}

.lrn-assess-content.content.lrn div.row {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    padding: 7px 16px;
    background: $gray;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 0px 0px 8px;
  }
}
.lrn-assess-content.content.lrn {
  padding-bottom: 20px; //create space for the after pseudo element (for attribution)
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px 16px;
    background: $gray-light;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 0 8px;
  }
  &::after {
    position: absolute;
    bottom: 5px;
    right: 0;
    left: 0;
    padding: 5px;
    margin-left: 10px;
    margin-right: 10px;
    background: $gray-light;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
  }
}

.vat-study-path-hint-banner {
  background-color: $blue-lighter;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
  a {
    color: $purple-dark
  }
}
.learnosity-box {
  div.lrn-region-group.lrn-group-top, .lrn-region.lrn-top-region {
    // Hide l8y nav menu on mobile
    display: none !important;
    @media #{$breakpoint-md-up} {
      display: inherit;
    }
  }
}
