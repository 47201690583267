@import "~sass-includes/index";

.assignment-container {
  @include centered($max-width);

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  table {
    border-collapse: collapse;
    font-family: $standard-font;
    border: 1px solid;
    border-color: $gray-light;
    width: 100%;
  }
  thead {
    background-color: $gray-light;
    text-align: left;
    width: 90%;
    height: 61px;
    background-color: $gray-light;
  }
  th {
    font-size: 13px;
    font-weight: normal;
  }
  tr {
    height: 30px;
    &.first-row {
      height: 10px;
    }
    &.spacer-row {
      height: 20px;
      border-top: 1px solid;
      border-color: $gray-light;
    }
  }
  td {
    padding: 2px 4px;
    text-align: left;
    font-size: 13px;
    font-weight: normal;
    >a:link {
      @extend %standardLink;
      font-size: 13px;
    }
    >a:visited {
      @extend %standardLink;
      font-size: 13px;
    }
    &.icon {
      text-align: right;
      svg {
        fill: $recapture-color;
      }
    }
  }
  .completed-on-time-badge-sm {
    @extend %status-badge-sm;
    background-color: $green;
  }
  .completed-late-badge-sm {
    @extend %status-badge-sm;
    background-color: $gold;
  }
}

h2.assignment-container__heading {
  @extend %page-heading;
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  margin-left: 10px;
}

.assignment-container__table-row {
  &[data-ispastdue="true"] {
    color: $black;
  }
  &[data-isopen="false"] {
    color: $gray-dark;
    font-size: 12px;
  }
}

.better-tooltip__container.instruction__assignment-points-policy-tooltip {
  width: 440px;
  .instructions__content.row {
    flex-direction: column;
    .instructions_infobox-title {
      margin-top: 2em;
    }
    > div {
      max-width: 100%;
    }
  }
}

.instruction__assignment-points-policy-tooltip,
.instruction__points-tooltip {
  cursor: pointer;
}
