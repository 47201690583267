@import "~sass-includes/index";

.duplicate-courses {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  margin: 0px 30px;

  input[type="text"] {
    width: 500px;
    min-width: 400px;
    border: 1px solid;
    border-color: $gray;
    border-radius: 7px;
    padding: 10px 5px;
    line-height: normal;
  }

  button.submit-button {
    width: 140px;
    margin: 5px;
  }

  .duplicate-courses-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    label {
      margin-right: 8px;
    }
    input#original-course-id-search {
      width: 340px;
      min-width: 340px;
    }
    font-weight: bold;
    padding: 32px 0 24px;
    .duplicate-courses-search__form {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .api-error {
      width: 33%;
    }
  }

  .duplicate-courses-courses {
    display: flex;
    flex-direction: row;
    justify-content: center;

    input {
      height: 50px;
    }

    label {
      font-weight: bold;
    }

    textarea {
      font-family: $standard-font;
      width: 500px;
      border: 1px solid;
      border-color: $gray;
      border-radius: 7px;
      padding: 10px 5px;
      line-height: normal;
    }

    // only apply margin to input labels
    form > label, > div > label {
      margin: 8px 0;
    }

    .day-select {
      width: 40%;
      label.day-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        .day-name {
          margin-left: 10px;
          font-weight: normal;
        }
        > input {
          height: 30px;
        }
      }
    }

    .react-datepicker-wrapper {
      width: 200px;
      input[type="text"] {
        min-width: 200px;
        max-width: 200px;
      }
    }

    .duplicate-courses-courses__original-course {
      display: flex;
      flex-direction: column;
    }

    .duplicate-courses-courses__duplicate-course {
      .duplicate-courses-courses__duplicate-form {
        display: flex;
        flex-direction: column;

        .duplicate-courses-courses__new-course-id {
          display: flex;
          flex-direction: row;
        }

        .duplicate-courses-courses__new-duplicate-custom {
          display: flex;
          flex-direction: row;
          align-items: center;
          label {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .duplicate-courses-search__loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    width: auto;
    > span {
      margin-left: 10px;
    }
  }
}
