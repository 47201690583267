@import "~sass-includes/index";

.assessment-taker-header {
  margin-bottom: 30px;
}

.assessment-taker-header__name {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: $standard-font;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-h3;
  line-height: 41px;
  padding-top: 16px;
  padding-bottom: 10px;
  .assessment-taker-root__assessment-name {
    @extend %hreset;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-h3;
    display: inline;
    margin-right: 8px;
  }
}

.assessment-taker-header__subhead {
  line-height: 24px;
  .past-due-badge,
  .completed-on-time-badge,
  .completed-late-badge {
    background-color: $red;
    color: $white;
    padding: 4px 8px;
    border-radius: 0;
    margin-left: 8px;
    font-size: small;
    font-weight: bold;
  }
  .completed-on-time-badge {
    background-color: $green;
  }
  .completed-late-badge {
    background-color: $gold;
  }
}
