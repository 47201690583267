@import "~sass-includes/index";

.instructions__content {
  padding: 16px 8px;
  @media #{$breakpoint-sm-up} {
    padding: 50px 55px 30px;
  }
  > div {
    margin-left: 1em;
    margin-right: 1em;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
  }
  li {
    margin: 15px 0;
  }
  .instructions__content-title {
    font-family: $standard-font;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 20px;
    margin-bottom: 2em;
  }
  .instructions__content-text {
    font-family: $standard-font;
    font-size: $font-size-sm;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }
  .instructions_infobox-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    margin-bottom: 2em;
  }
  width: 100%;
  > div {
    flex: 1;
  }
}

// tooltip shown when hovering the points in the Assignments' page
.instruction__assignment-points-policy-tooltip-content {
  .instructions__content {
    padding: 0;
    .instructions__content-title {
      //line-height: 20px;
      margin-bottom: 1em;
    }
    .instructions_infobox-title {
      //line-height: 20px;
      margin-bottom: 1em;

    }
  }
}
