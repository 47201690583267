
@import "~sass-includes/index";

$popup-padding: 10px;

@keyframes tooltip-in__right {
  0% {
    opacity: 0;
    transform: translate3d(0, 0px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d($popup-padding, 0, 0);
  }
}

@keyframes tooltip-in__top {
  0% {
    opacity: 0;
    transform: translate3d(0, 0px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, -$popup-padding, 0);
  }
}

@keyframes tooltip-in__bottom {
  0% {
    opacity: 0;
    transform: translate3d(0, 0px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, $popup-padding, 0);
  }
}

@keyframes tooltip-in__left {
  0% {
    opacity: 0;
    transform: translate3d(0, 0px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(-$popup-padding, 0, 0);
  }
}

.better-tooltip-popup {
  position: fixed;
  .better-tooltip__container {
    z-index: #{$zindexPopup + 1};
    position: relative;
    width: 100%;
    background-color: $white;
    border: solid 1px $coral-light;
    border-radius: 8px;
    padding: 20px;
    box-shadow: $tooltip-shadow;
    font-size: $font-size-xs;
    animation: tooltip-in__right 400ms $ease-out-quint both;
    line-height: 120%;
    max-width: 400px;
    &.better-tooltip__wide {
      max-width: 600px;
      min-width: 400px;
    }
  }
  &.popup-top {
    transform: translate(-50%, -100%);
    .better-tooltip__container {
      animation: tooltip-in__top 400ms $ease-out-quint both;
    }
  }

  &.popup-bottom {
    transform: translate(-50%, 0%);
    .better-tooltip__container {
      animation: tooltip-in__bottom 400ms $ease-out-quint both;
    }
  }

  &.popup-bottom-left {
    transform: translate(-98%, 0%);
    .better-tooltip__container {
      animation: tooltip-in__left 400ms $ease-out-quint both;
    }
  }

  &.popup-bottom-right {
    transform: translate(0%, 0%);
    .better-tooltip__container {
      animation: tooltip-in__bottom 400ms $ease-out-quint both;
    }
  }

  &.popup-left {
    transform: translate(-100%, -50%);
    .better-tooltip__container {
      animation: tooltip-in__left 400ms $ease-out-quint both;
    }
  }
  &.popup-right {
    transform: translate(0%, -50%);
    .better-tooltip__container {
      animation: tooltip-in__right 400ms $ease-out-quint both;
    }
  }
}

.better-tooltip__trigger {
  cursor: default;
}
