@import "~sass-includes/index";

.dev__main {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 30px;
  .dev-demo.row {
    width: 100%;
    border-bottom: 2px dashed $gray;
    padding-bottom: 12px;
    ul {
      @extend %list-reset;
    }
  }

  .kebab-demo {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .toggle-wrap {
    .toggle-switch {
      margin-top: 12px;
    }
  }
  .icons-list {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        margin-right: 10px;
        color: $black;
      }
    }
  }
}
.persisted-expando {
  position: relative;
  button {
    width: 100%;
    display: flex;
    align-items: center;
    svg {
      transition: all 150ms linear;
    }
  }
  &[data-expanded="false"] {
    button {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
}

[data-lrn-widget-container] {
  max-height: none;
}
.color-town {
  width: 100%;
  table {
    width: 100%;
  }
  .wcag-large-text {
    font-size: 19px;
  }
  td.color-town-row__name, td.swatch {
    text-align: center;
    vertical-align: middle;
  }
  .status-cell {
    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .troof {
    &[data-pass="true"] {
      color: $green;
    }
    &[data-pass="false"] {

      color: $red;
    }
  }
}

.preclass-assessment {
  width: 100%;
  height: 100%;
  margin-top: 35px;
}

.demo-assessment__footer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tooltip-dev {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 300px;
}

.font-size-xxs {
  font-size: $font-size-xxs;
}
.font-size-xs {
  font-size: $font-size-xs;
}
.font-size-sm {
  font-size: $font-size-sm;
}
.font-size-md {
  font-size: $font-size-md;
}
.font-size-lg {
  font-size: $font-size-lg;
}
.font-size-xl {
  font-size: $font-size-xl;
}
.font-size-xxl {
  font-size: $font-size-h4;
}
