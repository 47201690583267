@import "~sass-includes/index";

.course-list-controller {
  @include centered($max-width);
}

.student-home__grid {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.student-home__grid-left {
  width: calc(60% - 15px);
}
.student-home__grid-right {
  width: calc(40% - 15px);
}
