@import "~sass-includes/index";
.login {
  min-height: 100vh;
  background-color: $gray-dark;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login__box {
  width: 300px;
  padding: 20px;
  background-color: $white;
  border-radius: 5px;
  h1 {
    text-align: center;
    color: $purple;
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  h2 {
    text-align: center;
    color: $gray-dark;
    font-size: $font-size-h4;
    font-weight: 100;
  }
  input[type="text"],
  input[type="password"] {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid $gray;
    padding: 20px;
  }
  input[type="submit"] {
    @extend %button;
    display: block;
    margin: 20px auto;
  }
}
.login__error {
  color: $red;
  text-align: center;
  font-size: $font-size-xs;
  margin-bottom: 20px;
}

.login__email_block {
  text-align: center;
}

.login__info {
  color: $green;
  text-align: center;
  font-size: $font-size-xs;
  margin-bottom: 20px;
}

.notification-message {
  font-size: $font-size-xs;
  margin-bottom: 12px;
  padding: 8px;
  background-color: $coral-light;
  border-radius: 5px;
  text-align: center;
}

.userAgreement__box {
  padding: 12px 16px;
  @media #{$breakpoint-md-up} {
    padding: 1em;
    margin: 0 20%;
  }
  .userAgreement__action {
    padding-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .user-agreement__privacy-button {
    @extend %standardLink;
    font-size: large;
    text-align: left;
  }
  .expando__trigger {
    svg {
      margin-right: 4px;
    }
  }
  .agreement__agree-button {
    @extend %button;
    &:disabled {
      background: $gray-light;
      border: 1px solid $border-color;
      color: $button-default-color-disabled;
    }
  }

  .agreement__cancel-button {
    @include secondary-button;
    margin-right: 50px;
  }

  .content {
    font-size: 0.8em;
  }
}
