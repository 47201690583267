@import "~sass-includes/index";

.course-qa-check {
  table {
    border-collapse: collapse;
    font-family: $standard-font;
    border: 1px solid;
    border-color: $gray;
    box-shadow: 2px 2px $gray-light;
    width: 100%;
  }
  thead {
    text-align: center;
  }
  tbody {
    text-align: left;
  }
  th {
    font-size: $font-size-md;
    font-weight: bold;
    border: 1px solid $gray-dark;
    padding: 15px 5px;
    background-color: $gray;
  }
  tr {
    height: 30px;
  }
  td {
    padding: 15px 5px;
    font-size: $font-size-sm;
    border: 1px solid $gray-dark;
  }

  .course-qa-check__check {
    width: 30%;
  }
  .course-qa-check__results {
    width: 70%;
  }
}
