@import "~sass-includes/index";

.class-session-card {
  width: 100%;
  background-color: $gray;
  border-radius: 10px;
  padding: 12px;
  @media #{$breakpoint-sm-up} {
    padding: 24px;
  }
}

.class-session-card__contain {
  @extend .codon-card;
  margin-bottom: 18px;
  overflow: hidden;
  h3 {
    font-size: $font-size-md;
    font-weight: 600;
    text-transform: uppercase;
    color: $gray-dark;
    margin: 0;
    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      background-color: $gray-dark;
      border-radius: 100%;
      margin-right: 20px;
      margin-top: -2px;
    }
  }
  &[data-type="pre"] h3:before {
    background-color: get("assess-type.preclass");
  }
  &[data-type="post"] h3:before {
    background-color: get("assess-type.homework");
  }
  &[data-type="materials"] h3:before {
    visibility: hidden;
  }
}

.class-session-card__contain-header {
  background-color: $gray-light;
  padding: 21px 30px;
}

.class-session-card__contain-body {
  padding: 21px 30px;
  h3 { padding-bottom: 20px; }
  hr {
    width: calc(100% + 60px);
    position: relative;
    left: -30px;
    border: 0;
    border-bottom: 1px solid $gray;
    margin: 20px 0px;
  }
}

.class-session-card__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.class-session-card__date {
  font-size: $font-size-xl;
  font-weight: 600;
  color: $gray-dark;
}

.class-session-card__topic-banner {
  width: calc(100% + 60px);
  margin-left: -30px;
  padding-top: 10%;
  background-size: cover;
  position: relative;
  span {
    position: absolute;
    display: block;
    width: 100%;
    background-color: $gray-light;
    backdrop-filter: blur(3px);
    bottom: 0;
    left: 0;
    font-size: $font-size-xl;
    font-weight: 500;
    color: $black;
    padding: 13px 17px;
  }
}

.class-session-card__lo-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 30px;
}

.class-session-card__lo-item {
  display: flex;
  margin: 0;
  > span {
    display: block;
    &:first-child {
      font-size: $font-size-xs;
      font-weight: 700;
      width: 70px;
      color: $black;
    }
    &:last-child {
      font-size: $font-size-xs;
      color: $black;
      font-weight: 500;
      width: calc(100% - 70px);
    }
    margin-bottom: 18px;
  }
}

.class-session-card__assessment-list,
.class-session-card__clr-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  .completed-on-time-badge-sm,
  .completed-late-badge-sm {
    @extend %status-badge-sm;
    background-color: $green;
    margin-left: 8px;
  }
  .completed-late-badge-sm {
    background-color: $gold;
  }
}

.class-session-card__clr-item {
  margin: 10px 0;
  > span {
    margin-left: 25px;
    display: block;
    font-size: $font-size-xs;
    color: $black;
  }
}

.class-materials-list {}

.student-assessment-list {
  .student-class-session-view__card-row {
    margin-bottom: 8px;
  }
}
